import React from "react";
import "./navbar.style.css";
import logo from "../../../assets/images/mukund logo-01.png";
import { FiFacebook, FiInstagram, FiMapPin } from "react-icons/fi";

const Navbar = () => {
	return (
		<div className="navbar-container">
			<div className="navbar-logo" onClick={() => (window.location.href = "/")}>
				<img src={logo} alt="logo" />
			</div>
			<div className="navbar-menus">
				<div className="menu-item" onClick={() => (window.location.href = "/")}>
					<p>HOME</p>
				</div>
				<div className="menu-item" onClick={() => (window.location.href = "/jewellery/Any/Any/Any/Latest")}>
					<p>JEWELLERY</p>
				</div>
				<div className="menu-item" onClick={() => (window.location.href = "/contact")}>
					<p>CONTACT</p>
				</div>
				<div className="menu-item" onClick={() => (window.location.href = "/jewellery/Any/Any/Any/Exclusive")}>
					<p>EXCLUSIVE</p>
				</div>
			</div>
			<div className="navbar-icons">
				<a href="https://bit.ly/39gS3nP" target="_blank">
					<FiFacebook />
				</a>
				<a href="https://bit.ly/3NHczgj" target="_blank">
					<FiInstagram />
				</a>
				<a href="https://bit.ly/3xozFSa" target="_blank">
					<FiMapPin />
				</a>
			</div>
		</div>
	);
};

export default Navbar;
