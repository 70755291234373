import React from "react";

import "./footer.style.css";

import logo from "../../../assets/images/mukund logo-01.png";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-wrapper">
        <div className="column1">
          <div className="top">
            <img src={logo} alt="logo" onClick={() => (window.location.href = "/")} />
            <p>Copyright {new Date().getFullYear()} Mukund Jewellers | All rights reserved.</p>
          </div>
        </div>
        <div className="column2">
          <h1>Customer Service</h1>
          <a className="footer-link" href={"/contact"}>
            Contact us
          </a>
          <a className="footer-link" href={"/contact"}>
            Book an appointment
          </a>
        </div>
        <div className="column2">
          <div>
            <span className="custom-link">
              <FaFacebookF />
            </span>
            <span className="custom-link">
              <FaTwitter />
            </span>
            <span className="custom-link">
              <FaInstagram />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
