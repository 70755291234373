import React, { Component } from "react";
import "./MobileNav.css";
import logo from "../../../assets/images/mukund logo-01.png";
import line from "../../../assets/images/line.svg";
import { FiFacebook, FiInstagram, FiMapPin } from "react-icons/fi";

class MobileNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hamburger: "hamburger-menu",
			showList: false,
			showMenu: false
		};
		this.windowOffset = 0;
	}

	menuToggleHandler = () => {
		if (this.state.hamburger === "hamburger-menu cross") {
			this.setState(
				{
					hamburger: "hamburger-menu joint",
					showMenu: false
				},
				() => {
					setTimeout(() => {
						this.setState({
							hamburger: "hamburger-menu"
						});
					}, 500);
				}
			);
		} else {
			this.setState(
				{
					hamburger: "hamburger-menu joint",
					showMenu: true
				},
				() => {
					setTimeout(() => {
						this.setState({
							hamburger: "hamburger-menu cross"
						});
					}, 550);
				}
			);
		}
	};

	render() {
		return (
			<div className="mobilenav-container">
				<div className="navbar">
					<div className="logo" onClick={() => (window.location.href = "/")}>
						<img src={logo} alt="" />
					</div>
					<div className={this.state.hamburger} onClick={this.menuToggleHandler}>
						<div className="bar"></div>
						<div className="bar"></div>
						<div className="bar"></div>
					</div>
				</div>
				<div className={this.state.showMenu ? "menues-list active" : "menues-list"}>
					<div className="upper">
						<div className="menu-item" onClick={() => (window.location.href = "/")}>
							<p>HOME</p>
						</div>
						<div className="line">
							<img src={line} alt="" />
						</div>
						<div className="menu-item" onClick={() => (window.location.href = "/jewellery/Any/Any/Any/Latest")}>
							<p>JEWELLERY</p>
						</div>
						<div className="line">
							<img src={line} alt="" />
						</div>
						<div className="menu-item" onClick={() => (window.location.href = "/contact")}>
							<p>CONTACT</p>
						</div>
						<div className="line">
							<img src={line} alt="" />
						</div>
						<div className="menu-item" onClick={() => (window.location.href = "/jewellery/Any/Any/Any/Exclusive")}>
							<p>EXCLUSIVE</p>
						</div>
					</div>
					<div className="lower">
						<div className="logo-bottom">
							<img src={logo} alt="" />
						</div>
						<div className="media-link">
							<a href="https://bit.ly/39gS3nP" target="_blank">
								<FiFacebook />
							</a>
							<a href="https://bit.ly/3NHczgj" target="_blank">
								<FiInstagram />
							</a>
							<a href="https://bit.ly/3xozFSa" target="_blank">
								<FiMapPin />
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MobileNav;
