import React from "react";
import Marquee from "react-fast-marquee";
import { BsArrowDownShort } from "react-icons/bs";
import firebase from "firebase";
import "./home.style.css";

import line from "../../../assets/images/line.svg";

import divider from "../../../assets/images/line.svg";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
class HomePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			categories: [],
			activeSlide: 0,
			banners: [],
			box1: {
				h1: "",
				h5: "",
				p: "",
				img: "",
				edit: false
			},
			box2: {
				h1: "",
				h5: "",
				p: "",
				img: ""
			},
			box3: {
				h1: "",
				h5: "",
				p: "",
				img: ""
			},
			box4: {
				h1: "",
				h5: "",
				p: "",
				img: ""
			},
			box5: {
				h1: "",
				h5: "",
				p: "",
				img: ""
			},
			box6: {
				h1: "",
				h5: "",
				p: "",
				img: ""
			},
			box7: {
				h1: "",
				h5: "",
				p: "",
				img: ""
			},
			box8: {
				h1: "",
				h5: "",
				p: "",
				img: ""
			},
			box9: {
				h1: "",
				h5: "",
				p: "",
				img: ""
			},
			featuredList: [],
			loading: true,
			testimonial: [],
			activeTestimonial: 0
		};
	}

	componentDidMount() {
		firebase
			.firestore()
			.collection("settings")
			.doc("ZMuKUVDQPzQdcwkkFGqv")
			.get()
			.then((setting) => {
				var categories = [];
				for (var i = 0; i < setting.data().categories.length; i++) {
					categories.push(setting.data().categories[i]);
				}
				var box1,
					box2,
					box3,
					box4,
					box5,
					box6,
					box7,
					box8,
					box9 = {};
				box1 = setting.data().box1;
				box2 = setting.data().box2;
				box3 = setting.data().box3;
				box4 = setting.data().box4;
				box5 = setting.data().box5;
				box6 = setting.data().box6;
				box7 = setting.data().box7;
				box8 = setting.data().box8;
				box9 = setting.data().box9;
				this.setState({
					categories: categories,
					banners: setting.data().banners,
					box1,
					box2,
					box3,
					box4,
					box5,
					box6,
					box7,
					box8,
					box9,
					loading: false
				});
			});
		setInterval(() => {
			if (this.state.activeSlide === this.state.banners.length - 1) {
				this.setState({
					activeSlide: 0
				});
			} else {
				this.setState({
					activeSlide: this.state.activeSlide + 1
				});
			}
		}, 5000);
		firebase
			.firestore()
			.collection("products")
			.where("featured", "==", true)
			.limit(5)
			.get()
			.then((products) => {
				var featuredList = [];
				products.forEach((doc) => {
					var product = doc.data();
					product.id = doc.id;
					featuredList.push(product);
				});
				this.setState({
					featuredList
				});
			});

		firebase
			.firestore()
			.collection("testimonial")
			.get()
			.then((snap) => {
				var arr = [];
				snap.forEach((doc) => {
					var data = {};
					data.id = doc.id;
					data.image = doc.data().image;
					data.name = doc.data().name;
					data.text = doc.data().text;
					// if (arr.length < 2) {
					arr.push(data);
					// }
					this.setState({
						testimonial: arr,
						activeTestimonial: 0
					});
				});
			});
	}

	handleForward = () => {
		if (this.state.testimonial.length - 1 === this.state.activeTestimonial) {
			this.setState({
				activeTestimonial: 0
			});
		} else {
			this.setState({
				activeTestimonial: this.state.activeTestimonial + 1
			});
		}
	};

	handleBackward = () => {
		if (this.state.activeTestimonial === 0) {
			this.setState({
				activeTestimonial: this.state.testimonial.length - 1
			});
		} else {
			this.setState({
				activeTestimonial: this.state.activeTestimonial - 1
			});
		}
	};

	handleTestimonial = (e) => {
		this.setState({
			activeTestimonial: e
		});
	};

	render() {
		return (
			<div className="home-container">
				{/* Banner */}
				<div className="banner-container">
					<div className="slider-container">
						{this.state.banners.map((banner, index) => {
							return (
								<div className={this.state.activeSlide === index ? "slide active" : "slide"} key={index}>
									<img src={banner} alt={"banner" + index + 1} />
								</div>
							);
						})}
					</div>
					<div className="banner-content">
						<h1>Be Prepared For The Best And Beyond </h1>
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<p>scroll down </p>
							<div className="banner-arrow">
								<Marquee className="p-arrow" direction="right" gradient={false}>
									<span>
										<BsArrowDownShort />
									</span>
								</Marquee>
							</div>
						</div>
					</div>
					<div className="slider-dots">
						{this.state.banners.map((banner, index) => {
							return <div key={index} onClick={() => this.setState({ activeSlide: index })} className={this.state.activeSlide === index ? "slider-dot active" : "slider-dot"}></div>;
						})}
					</div>
				</div>

				{/* Grids */}
				<div className="advertisement-cards">
					<div className="advertisement-card top-start">
						<img src={this.state.box1.img} className="advertisement-card-bg-image" alt="ad-card1" />
						<div className="advertisement-card-content">
							<h1>{this.state.box1.h1}</h1>
							<h5>{this.state.box1.h5}</h5>
						</div>
					</div>
					<div className="advertisement-card full-center">
						<div className="advertisement-card-content">
							<h1>{this.state.box2.h1}</h1>
							<h5>{this.state.box2.h5}</h5>
							<img src={divider} alt="divider" className="advertisement-card-content-divider" />
							<p>{this.state.box2.p}</p>
						</div>
					</div>
					<div className="advertisement-card bottom-end">
						<img src={this.state.box3.img} className="advertisement-card-bg-image" alt="ad-card3" />
						<div className="advertisement-card-content">
							<h1>{this.state.box3.h1}</h1>
							<h5>{this.state.box3.h5}</h5>
						</div>
					</div>
					<div className="advertisement-card full-center">
						<div className="advertisement-card-content">
							<h1>{this.state.box4.h1}</h1>
							<h5>{this.state.box4.h5}</h5>
							<img src={divider} alt="divider" className="advertisement-card-content-divider" />
							<p>{this.state.box4.p}</p>
						</div>
					</div>
					<div className="advertisement-card top-center">
						<img src={this.state.box5.img} className="advertisement-card-bg-image" alt="ad-card3" />
						<div className="advertisement-card-content">
							<h1>{this.state.box5.h1}</h1>
							<h5>{this.state.box5.h5}</h5>
						</div>
					</div>
					<div className="advertisement-card full-center">
						<div className="advertisement-card-content">
							<h1>{this.state.box6.h1}</h1>
							<h5>{this.state.box6.h5}</h5>
							<img src={divider} alt="divider" className="advertisement-card-content-divider" />
							<p>{this.state.box6.p}</p>
						</div>
					</div>
					<div className="advertisement-card bottom-start">
						<img src={this.state.box7.img} className="advertisement-card-bg-image" alt="ad-card4" />
						<div className="advertisement-card-content">
							<h1>{this.state.box7.h1}</h1>
							<h5>{this.state.box7.h5}</h5>
						</div>
					</div>
					<div className="advertisement-card full-center">
						<div className="advertisement-card-content">
							<h1>{this.state.box8.h1}</h1>
							<h5>{this.state.box8.h5}</h5>
							<img src={divider} alt="divider" className="advertisement-card-content-divider" />
							<p>{this.state.box8.p}</p>
						</div>
					</div>
					<div className="advertisement-card top-end">
						<img src={this.state.box9.img} className="advertisement-card-bg-image" alt="ad-card5" />
						<div className="advertisement-card-content">
							<h1>{this.state.box9.h1}</h1>
							<h5>{this.state.box9.h5}</h5>
						</div>
					</div>
				</div>

				{/* About */}
				<div className="about-container">
					<div className="about-head">
						<div className="line"></div>
						<p>Mukund Jewellers</p>
						<div className="line"></div>
					</div>
					<div className="about-body">
						<p>
							<i>We followed our dream</i>
						</p>
						<p>
							<i>and found ourselves living an unforgettable adventure</i>
						</p>
						<img src={line} alt="line" />
					</div>
					<div className="about-foot">
						<p>We are a name that implies moments of joy and excitement, a name that means stylistic originality and design in any</p>
						<p>language, there for you to discover in all of its many expressions.</p>
					</div>
				</div>

				{/* Exclusive collection */}

				<div className="exclusive-container">
					<div className="exclusive-head">
						<p>Exclusive Collection</p>
					</div>
					<div className="exclusive-body">
						<button onClick={() => (window.location.href = "/jewellery/Any/Any/Any/Exclusive")} className="custom-link">
							DISCOVER THE SELECTION
						</button>
						<img src={line} alt="line" />
					</div>

					<div className="exclusive-foot">
						{this.state.featuredList.map((featuredItem, index) => {
							return (
								<div className="exclusive-grid" onClick={() => (window.location.href = "/product/" + featuredItem.id)} key={index}>
									<img src={featuredItem.thumbnail} alt="thumbnail" />
									<div className="grid-overlay">
										<button>DISCOVER</button>
									</div>
									<p className="exclusive-title">{featuredItem.title}</p>
								</div>
							);
						})}
					</div>
				</div>

				{/* Gemstone */}
				<div className="gemstone-container">
					<div className="gemstone-head">
						<p>Shop By Gemstone</p>
					</div>
					<div className="gemstone-body">
						<img src={line} alt="line" />
					</div>

					<div className="gemstone-foot">
						{this.state.categories.map((category, index) => {
							if (category.active === true) {
								return (
									<div className="gemstone-grid" onClick={() => (window.location.href = `/jewellery/Any/${category.name}/Any/Latest`)} key={index}>
										<img src={category.image} alt="gems" />
										<div className="gemstone-overlay">
											<p>{category.name}</p>
										</div>
									</div>
								);
							} else {
								return null;
							}
						})}
					</div>
				</div>

				{/* Testimonials */}
				<div className="testimonial-container">
					<div className="testimonial-head">
						<p>Testimonials</p>
					</div>
					<div className="testimonial-divider">
						<img src={line} alt="line" />
					</div>
					<div className="testimonial-selector-container">
						{this.state.testimonial.length > 1 ? (
							<div className="testimonial-selector-arrow" onClick={this.handleBackward}>
								<p>
									<IoIosArrowBack />
								</p>
							</div>
						) : null}
						<div className="testimonial-selector-carousel">
							{this.state.testimonial.length > 4 ? (
								<div
									className="testimonial-selector"
									onClick={() =>
										this.handleTestimonial(
											this.state.activeTestimonial - 2 >= 0
												? this.state.activeTestimonial - 2
												: this.state.activeTestimonial === 0
												? this.state.testimonial.length - 1 - this.state.activeTestimonial - 1
												: this.state.testimonial.length - 1
										)
									}>
									<img
										src={
											this.state.testimonial[
												this.state.activeTestimonial - 2 >= 0
													? this.state.activeTestimonial - 2
													: this.state.activeTestimonial === 0
													? this.state.testimonial.length - 1 - this.state.activeTestimonial - 1
													: this.state.testimonial.length - 1
											].image
										}
										alt="testimonial-user"
									/>
								</div>
							) : null}
							{this.state.testimonial.length > 2 ? (
								<div
									className="testimonial-selector"
									onClick={() => this.handleTestimonial(this.state.activeTestimonial - 1 >= 0 ? this.state.activeTestimonial - 1 : this.state.testimonial.length - 1)}>
									<img
										src={this.state.testimonial[this.state.activeTestimonial - 1 >= 0 ? this.state.activeTestimonial - 1 : this.state.testimonial.length - 1].image}
										alt="testimonial-user"
									/>
								</div>
							) : null}
							{this.state.testimonial.map((t, index) => {
								if (this.state.activeTestimonial === index) {
									return (
										<div className="testimonial-selector active" key={index}>
											<img src={t.image} alt="testimonial-user" />
										</div>
									);
								} else {
									return null;
								}
							})}
							{this.state.testimonial.length > 1 ? (
								<div
									className="testimonial-selector"
									onClick={() => this.handleTestimonial(this.state.activeTestimonial + 1 <= this.state.testimonial.length - 1 ? this.state.activeTestimonial + 1 : 0)}>
									<img
										src={this.state.testimonial[this.state.activeTestimonial + 1 <= this.state.testimonial.length - 1 ? this.state.activeTestimonial + 1 : 0].image}
										alt="testimonial-user"
									/>
								</div>
							) : null}
							{this.state.testimonial.length > 4 ? (
								<div
									className="testimonial-selector"
									onClick={() =>
										this.handleTestimonial(
											this.state.activeTestimonial + 2 <= this.state.testimonial.length - 1
												? this.state.activeTestimonial + 2
												: this.state.activeTestimonial + 2 - this.state.testimonial.length
										)
									}>
									<img
										src={
											this.state.testimonial[
												this.state.activeTestimonial + 2 <= this.state.testimonial.length - 1
													? this.state.activeTestimonial + 2
													: this.state.activeTestimonial + 2 - this.state.testimonial.length
											].image
										}
										alt="testimonial-user"
									/>
								</div>
							) : null}
						</div>
						{this.state.testimonial.length > 1 ? (
							<div className="testimonial-selector-arrow" onClick={this.handleForward}>
								<p>
									<IoIosArrowForward />
								</p>
							</div>
						) : null}
					</div>
					<div className="testimonial-body">
						<h1>{this.state.testimonial.length > 0 && this.state.testimonial[this.state.activeTestimonial].name}</h1>
						<p>{this.state.testimonial.length > 0 && this.state.testimonial[this.state.activeTestimonial].text}</p>
					</div>
				</div>
				{/* Book appointment */}

				<div className="book-container">
					<div className="book-head">
						<p>Book An Appointment</p>
					</div>
					<div className="book-body">
						<img src={line} alt="line" />
					</div>
					<div className="book-foot">
						<button className="custom-link" onClick={() => (window.location.href = "/contact")}>
							BOOK NOW
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default HomePage;
