import React from "react";
import firebase from "firebase";
import "./product.style.css";
import Loader from "../../components/loader/loader";
import Slider from "../../components/Slider/Slider";

class ProductDescription extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			productInfo: null,
			loading: true,
			data: ["1", "2", "3", "4", "5"],
			similarProd: [],
			similarProd2: [],
			currentId: null
		};
	}

	componentDidMount() {
		var productId = this.props.match.params.id;
		this.fetchedProduct(productId);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			var productId = this.props.match.params.id;
			this.fetchedProduct(productId);
		}
	}

	fetchedProduct = (productId) => {
		window.scrollTo(0, 0);
		this.setState({
			loading: true
		});
		firebase
			.firestore()
			.collection("products")
			.doc(productId)
			.get()
			.then((product) => {
				var p = product.data();
				p.id = product.id;
				console.log(p.category);
				firebase
					.firestore()
					.collection("products")
					.where("category", "==", p.category)
					.get()
					.then((allProd) => {
						console.log(allProd.size);
						var similar = [],
							similar2 = [];
						allProd.forEach((doc) => {
							var d = doc.data();
							d.id = doc.id;
							if (p.id !== d.id) {
								if (d.subCategory === p.subCategory) {
									similar.push(d);
								} else {
									similar2.push(d);
								}
							}
						});
						this.setState({
							similarProd: similar,
							similarProd2: similar2
						});
					});
				this.setState({
					productInfo: p,
					currentImageIndex: 0,
					loading: false
				});
			});
	};

	handleImage = (e) => {
		this.setState({
			currentImageIndex: e
		});
	};

	render() {
		return (
			<div className="product-container">
				{this.state.loading ? (
					<Loader />
				) : (
					<>
						<div className="product-details">
							<div className="product-left">
								<div className="image-list">
									{this.state.productInfo.images.map((image, index) => {
										return (
											<div onClick={() => this.handleImage(index)} className={this.state.currentImageIndex === index ? "s-img active" : "s-img"} key={index}>
												<img src={image} alt="product" />
											</div>
										);
									})}
								</div>
								<div className="image-main">
									<img src={this.state.productInfo.images[this.state.currentImageIndex]} alt="product" />
								</div>
							</div>
							<div className="product-right">
								<div className="product-breadcrumb">
									<p>
										Home / Jewellery / {this.state.productInfo.category} / {this.state.productInfo.subCategory}
									</p>
								</div>
								<div className="product-head">
									<h1>{this.state.productInfo.title}</h1>
								</div>
								<div className="product-desc">
									<h1>DESCRIPTION & DETAILS</h1>
									<p>{this.state.productInfo.description}</p>
									{this.state.productInfo.specifications.map((specification, index) => {
										return (
											<p>
												{specification.title}: {specification.content}
											</p>
										);
									})}
								</div>
							</div>
						</div>

						{this.state.similarProd.length > 0 ? (
							<div className="exclusive-container">
								<div className="exclusive-head">
									<p>Complete the look</p>
								</div>
								<div className="exclusive-foot">
									<Slider data={this.state.similarProd} title="Similar Products" view={false} />
								</div>
							</div>
						) : null}

						{this.state.similarProd2.length > 0 ? (
							<div className="exclusive-container">
								<div className="exclusive-head">
									<p>You may also like</p>
								</div>

								<div className="exclusive-foot">
									<Slider data={this.state.similarProd2} title="Similar Products" view={false} />
								</div>
							</div>
						) : null}

						<div className="feature-container">
							<div className="feature-foot">
								{/* <div className="feature-grid">
                  <img src={shipping} alt="icon" />
                  <div>
                    <p>FREE SHIPPING</p>
                    <span>Free standard shipping over Rs. 1500</span>
                  </div>
                </div> */}
								{/* <div className="feature-grid">
                  <img src={returnImg} alt="icon" />
                  <div>
                    <p>RETURNS</p>
                    <span>
                      Upto{" "}
                      {this.state.productInfo.returnlimit > 9
                        ? this.state.productInfo.returnlimit + " days"
                        : this.state.productInfo.returnlimit + " day"}{" "}
                      return
                    </span>
                  </div>
                </div> */}
								{/* <div className="feature-grid">
                  <img src={chatpng} alt="icon" />
                  <div>
                    <p>NEED HELP</p>
                    <span>Contact with our executive</span>
                  </div>
                </div> */}
							</div>
						</div>
					</>
				)}
			</div>
		);
	}
}

export default ProductDescription;
