import React from "react";
import "./Slider.css";
import Card from "../card/Card";
import Swiper, { Navigation } from "swiper";
import Loading from "../loader/loader";

export default class Slider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			products: []
			// loading: true,
		};
	}

	componentDidMount() {
		Swiper.use([Navigation]);
		var swiper = new Swiper(".swiper-container", {
			observer: true,
			breakpoints: {
				320: {
					slidesPerView: 2,
					spaceBetween: 20
				},
				640: {
					slidesPerView: "auto",
					spaceBetween: 20
				}
			},

			// Navigation arrows
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev"
			}
		});
		console.log(swiper);
	}

	render() {
		return (
			<div className="slider">
				{this.props.data.length > 0 ? (
					<div className="swiper-container">
						<div className="swiper-wrapper">
							{this.props.data.map((item, index) => (
								<div className="swiper-slide" key={index}>
									<Card item={item} />
								</div>
							))}
						</div>
						{/* <div className="swiper-button-prev"></div>
						<div className="swiper-button-next"></div> */}
					</div>
				) : (
					<Loading />
				)}
			</div>
		);
	}
}
