import React, { Component } from "react";

import "./contact.style.css";

import mapPng from "../../../assets/images/map.png";
import firebase from "firebase";

class ContactPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fname: "",
			lname: "",
			email: "",
			phone: "",
			company: "",
			subject: "",
			message: ""
		};
	}

	inputHandler = (e) => {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	submitHandler = () => {
		firebase
			.firestore()
			.collection("queries")
			.add({
				fname: this.state.fname,
				lname: this.state.lname,
				email: this.state.email,
				phone: this.state.phone,
				company: this.state.company,
				subject: this.state.subject,
				message: this.state.message
			})
			.then(() => {
				this.setState({
					fname: "",
					lname: "",
					email: "",
					phone: "",
					company: "",
					subject: "",
					message: ""
				});
			});
	};

	render() {
		return (
			<div className="contact-container">
				<div className="contact-banner">
					<p>HAVE A QUESTION ?</p>
					<h1>CONTACT US</h1>
				</div>

				{/* Book an appointment */}

				<div className="book-appointment">
					<button>BOOK AN APPOINTMENT</button>
				</div>

				{/* Registered office address and map */}
				<div className="contact-address">
					<div className="address-left">
						<h1>REGISTERED OFFICE</h1>
						<div className="address-section">
							<div>
								<p>MUKUND JEWELERS</p>
								<span>
									Kalakar St, Raja Katra,
									<br /> Bara Bazar, Jorasanko,
									<br /> Kolkata,
									<br /> West Bengal 700007
								</span>
							</div>
							<div>
								<p>TEL</p>
								<span>+91 98316 97794</span>
							</div>
							<div>
								<p>FAX</p>
								<span>+91 98316 97794</span>
							</div>
						</div>
					</div>
					<div className="address-right">
						<img src={mapPng} alt="map" />
					</div>
				</div>

				{/* contact form */}
				<div className="contact-form">
					<div className="contact-form-head">
						<h1>TALK TO US</h1>
					</div>
					<div className="contact-form-body">
						<div className="form-group">
							<label style={{ whiteSpace: "nowrap" }}>FIRST NAME</label>
							<input type="text" onChange={this.inputHandler} name="fname" value={this.state.fname} />
						</div>
						<div className="form-group">
							<label>LAST NAME</label>
							<input type="text" onChange={this.inputHandler} name="lname" value={this.state.lname} />
						</div>
						<div className="form-group">
							<label>EMAIL</label>
							<input type="text" onChange={this.inputHandler} name="email" value={this.state.email} />
						</div>
						<div className="form-group">
							<label>TELEPHONE</label>
							<input type="text" onChange={this.inputHandler} name="phone" value={this.state.phone} />
						</div>
						<div className="form-group">
							<label>COMPANY</label>
							<input type="text" onChange={this.inputHandler} name="company" value={this.state.company} />
						</div>
						<div className="form-group">
							<label>SUBJECT</label>
							<input type="text" onChange={this.inputHandler} name="subject" value={this.state.subject} />
						</div>
						<div className="form-group2">
							<label>MESSAGE</label>
							<textarea rows={5} onChange={this.inputHandler} name="message" value={this.state.message} />
						</div>
						<div className="form-group3">
							<button onClick={this.submitHandler}>SUBMIT</button>
						</div>
					</div>
					<div className="contact-form-foot"></div>
				</div>
			</div>
		);
	}
}

export default ContactPage;
