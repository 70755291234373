import React, { Component } from "react";
import "./allProducts.css";
import Filter from "../../components/Filter/Filter";
import MobileFilter from "../../components/MobileFilter/MobileFilter";
import firebase from "firebase";
import Card from "../../components/card/Card";
import Loader from "../../components/loader/loader";
import Lottie from "lottie-react-web";
import animation from "../../../assets/25943-nodata.json";

class AllProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allProducts: [],
      filtredProducts: [],
      searchItem: "",
      loading: true,
      material: "Any",
      type: "Any",
      metal: "Any",
      sort: "Latest",
      showFilter: false,
    };
    this.windowOffset = 0;
  }

  componentDidMount() {
    var { query1, query2, query3, query4 } = this.props.match.params;
    firebase
      .firestore()
      .collection("products")
      .orderBy("date", "desc")
      .get()
      .then((snap) => {
        var finalData = [];
        snap.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          finalData.push(d);
        });
        this.setState({ allProducts: finalData, filtredProducts: finalData, loading: false }, async () => {
          var s1 = await this.handleFilter("Type", query1);
          var s2 = await this.handleFilter("Material", query2);
          var s3 = await this.handleFilter("Metal", query3);
          var s4 = await this.handleFilter("Sort", query4);
          console.log(s1, s2, s3, s4);
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ allProducts: [], filtredProducts: [], loading: false });
      });
  }

  handleFilter = (t, v) => {
    this.setState(
      {
        loading: true,
        type: t === "Type" ? v : this.state.type,
        material: t === "Material" ? v : this.state.material,
        metal: t === "Metal" ? v : this.state.metal,
        sort: t === "Sort" ? v : this.state.sort,
      },
      () => {
        var allProducts = this.state.allProducts;

        var newAllProducts = [];

        if (this.state.type === "Any" && this.state.material === "Any" && this.state.metal === "Any") {
          for (var i = 0; i < allProducts.length; i++) {
            if (this.state.sort === "Latest") {
              newAllProducts.push(allProducts[i]);
            } else {
              if (allProducts[i].featured) {
                newAllProducts.push(allProducts[i]);
              }
            }
          }
        } else if (this.state.type !== "Any" && this.state.material === "Any" && this.state.metal === "Any") {
          for (var i2 = 0; i2 < allProducts.length; i2++) {
            if (allProducts[i2].subCategory === this.state.type) {
              if (this.state.sort === "Latest") {
                newAllProducts.push(allProducts[i2]);
              } else {
                if (allProducts[i2].featured) {
                  newAllProducts.push(allProducts[i2]);
                }
              }
            }
          }
        } else if (this.state.type !== "Any" && this.state.material === "Any" && this.state.metal !== "Any") {
          for (var i3 = 0; i3 < allProducts.length; i3++) {
            if (allProducts[i3].subCategory === this.state.type) {
              if (this.state.sort === "Latest") {
                newAllProducts.push(allProducts[i3]);
              } else {
                if (allProducts[i3].featured) {
                  newAllProducts.push(allProducts[i3]);
                }
              }
            }
          }
        } else if (this.state.type !== "Any" && this.state.material !== "Any" && this.state.metal === "Any") {
          for (var i4 = 0; i4 < allProducts.length; i4++) {
            if (allProducts[i4].subCategory === this.state.type) {
              if (this.state.sort === "Latest") {
                newAllProducts.push(allProducts[i4]);
              } else {
                if (allProducts[i4].featured) {
                  newAllProducts.push(allProducts[i4]);
                }
              }
            }
          }
        } else if (this.state.type === "Any" && this.state.material !== "Any" && this.state.metal === "Any") {
          for (var i5 = 0; i5 < allProducts.length; i5++) {
            if (allProducts[i5].category === this.state.material) {
              if (this.state.sort === "Latest") {
                newAllProducts.push(allProducts[i5]);
              } else {
                if (allProducts[i5].featured) {
                  newAllProducts.push(allProducts[i5]);
                }
              }
            }
          }
        } else if (this.state.type === "Any" && this.state.material !== "Any" && this.state.metal !== "Any") {
          for (var i6 = 0; i6 < allProducts.length; i6++) {
            if (allProducts[i6].category === this.state.material) {
              if (this.state.sort === "Latest") {
                newAllProducts.push(allProducts[i6]);
              } else {
                if (allProducts[i6].featured) {
                  newAllProducts.push(allProducts[i6]);
                }
              }
            }
          }
        } else if (this.state.type !== "Any" && this.state.material !== "Any" && this.state.metal === "Any") {
          for (var i7 = 0; i7 < allProducts.length; i7++) {
            if (allProducts[i7].category === this.state.material) {
              if (this.state.sort === "Latest") {
                newAllProducts.push(allProducts[i7]);
              } else {
                if (allProducts[i7].featured) {
                  newAllProducts.push(allProducts[i7]);
                }
              }
            }
          }
        } else if (this.state.type === "Any" && this.state.material === "Any" && this.state.metal !== "Any") {
          for (var i8 = 0; i8 < allProducts.length; i8++) {
            if (allProducts[i8].tag === this.state.metal) {
              if (this.state.sort === "Latest") {
                newAllProducts.push(allProducts[i8]);
              } else {
                if (allProducts[i8].featured) {
                  newAllProducts.push(allProducts[i8]);
                }
              }
            }
          }
        } else if (this.state.type !== "Any" && this.state.material === "Any" && this.state.metal !== "Any") {
          for (var i9 = 0; i9 < allProducts.length; i9++) {
            if (allProducts[i9].tag === this.state.material) {
              if (this.state.sort === "Latest") {
                newAllProducts.push(allProducts[i9]);
              } else {
                if (allProducts[i9].featured) {
                  newAllProducts.push(allProducts[i9]);
                }
              }
            }
          }
        } else if (this.state.type === "Any" && this.state.material !== "Any" && this.state.metal !== "Any") {
          for (var i10 = 0; i10 < allProducts.length; i10++) {
            if (allProducts[i10].tag === this.state.material) {
              if (this.state.sort === "Latest") {
                newAllProducts.push(allProducts[i10]);
              } else {
                if (allProducts[i10].featured) {
                  newAllProducts.push(allProducts[i10]);
                }
              }
            }
          }
        } else {
          for (var i11 = 0; i11 < allProducts.length; i11++) {
            if (allProducts[i11].category === this.state.material && allProducts[i11].subCategory === this.state.type) {
              if (this.state.sort === "Latest") {
                newAllProducts.push(allProducts[i11]);
              } else {
                if (allProducts[i11].featured) {
                  newAllProducts.push(allProducts[i11]);
                }
              }
            }
          }
        }
        this.setState({
          loading: false,
          filtredProducts: newAllProducts,
        });
      }
    );
    return "done";
  };

  toggleFilter = () => {
    if (this.state.showFilter === false) {
      this.windowOffset = window.scrollY;
      document.body.setAttribute("style", `position: fixed; top: -${this.windowOffSet}px; left: 0; right: 0;`);
    } else {
      document.body.setAttribute("style", "   ");
    }
    this.setState({ showFilter: !this.state.showFilter });
  };

  render() {
    return (
      <div className="all-products-page">
        <Filter
          handleFilter={this.handleFilter}
          type={this.state.type}
          material={this.state.material}
          metal={this.state.metal}
          weight={this.state.wtRange}
          sort={this.state.sort}
        />
        {this.state.showFilter && (
          <MobileFilter
            handleFilter={this.handleFilter}
            type={this.state.type}
            material={this.state.material}
            metal={this.state.metal}
            weight={this.state.wtRange}
            sort={this.state.sort}
            toggleFilter={this.toggleFilter}
            showFilter={this.showFilter}
          />
        )}
        {this.state.loading ? (
          <div className="product-list">
            <Loader />
          </div>
        ) : (
          <div className="product-list">
            {this.state.filtredProducts.length > 0 ? (
              this.state.filtredProducts.map((product, index) => {
                return <Card item={product} key={index} />;
              })
            ) : (
              <div className="lottie-loader">
                <Lottie
                  options={{
                    animationData: animation,
                  }}
                />
                <p>No products found</p>
              </div>
            )}
          </div>
        )}
        {/* <MayLike /> */}
        {!this.state.showFilter && (
          <div className="mob-filter" onClick={this.toggleFilter}>
            <div className="filer-icon">Filter</div>
          </div>
        )}
      </div>
    );
  }
}

export default AllProducts;
