import React, { Component } from "react";
import firebase from "firebase";
import { FaChevronCircleDown } from "react-icons/fa";
import { IoFilterSharp } from "react-icons/io5";
import "./MobileFilter.css";

class MobileFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeClass: false,
			box_1: false,
			box_2: false,
			box_3: false,
			box_4: false,
			wt_range: [
				{ name: "0-2 gms", value: [0, 2] },
				{ name: "2-5 gms", value: [2, 5] },
				{ name: "5-10 gms", value: [5, 10] }
			],
			categories: [],
			subCategories: [],
			tags: [],
			searchItem: "",
			sortItem: ["Latest", "Exclusive"],
			selectedSortitem: "Latest",
			sortList: false,
			typeList: false,
			metalList: false,
			materialList: false,
			weigthList: false
		};
	}

	async componentDidMount() {
		var settingsSnap = await firebase.firestore().collection("settings").doc("ZMuKUVDQPzQdcwkkFGqv").get();
		var categories = settingsSnap.data().categories;
		var types = [];
		var materials = [];
		var metals = [];
		for (var i = 0; i < categories.length; i++) {
			materials.push(categories[i].name);
			console.log(categories[i].subcategories.length);
			for (var j = 0; j < categories[i].subcategories.length; j++) {
				if (!types.includes(categories[i].subcategories[j].name)) {
					types.push(categories[i].subcategories[j].name);
					for (var k = 0; k < categories[i].subcategories[j].tags.length; k++) {
						if (!metals.includes(categories[i].subcategories[j].tags[k].tag)) {
							metals.push(categories[i].subcategories[j].tags[k].tag);
						}
					}
				}
			}
		}
		this.setState({
			categories: materials,
			subCategories: types,
			tags: metals
		});
	}

	productTypeHandler = (e) => {
		this.props.handleFilter("Type", e);
		this.props.toggleFilter();
	};

	wtRangeHandler = (e) => {
		this.props.handleFilter("Weight", e);
		this.props.toggleFilter();
	};

	productMaterialHandler = (e) => {
		this.props.handleFilter("Material", e);
		this.props.toggleFilter();
	};

	productMetalHandler = (e) => {
		this.props.handleFilter("Metal", e);
		this.props.toggleFilter();
	};

	sortByHandler = (e) => {
		this.props.handleFilter("Sort", e);
		this.props.toggleFilter();
	};

	render() {
		return (
			<div className="mob-filter-container">
				<div className={this.props.showFilter ? "mob-filter-content mob-filter-content-active" : "mob-filter-content  "}>
					<div className="mob-filter-body mob-mob-filter-body">
						<div className="mob-filter-header">
							<div className="header-text">
								<p>Filter</p>
								<div className="filter-icon">
									<IoFilterSharp className="react-filter-icon" />
								</div>
							</div>
							<div className="cancel" onClick={this.props.toggleFilter}>
								<div className="bar"></div>
								<div className="bar"></div>
							</div>
						</div>
						<div
							className="mob-filter-menu"
							onClick={() =>
								this.setState({
									typeList: !this.state.typeList,
									sortList: false,

									metalList: false,
									materialList: false,
									weigthList: false
								})
							}>
							<div className="mob-filter-by-prodyct-type mob-filter-type">
								<p>Probuct Type : {this.props.type}</p>
								<div className="dd-icon">
									<FaChevronCircleDown className="dropdown" />
								</div>
							</div>
							{this.state.typeList && (
								<div className="list">
									<p onClick={() => this.productTypeHandler("Any")} className="sub-menu-item">
										Any
										{"Any" === this.props.type ? (
											<span>
												<i class="fas fa-check"></i>
											</span>
										) : null}
									</p>
									{this.state.subCategories.map((item, index) => {
										return (
											<p onClick={() => this.productTypeHandler(item)} className="sub-menu-item" key={index}>
												{item}
												{item === this.props.type ? (
													<span>
														<i class="fas fa-check"></i>
													</span>
												) : null}
											</p>
										);
									})}
								</div>
							)}
						</div>
						<div
							className="mob-filter-menu"
							onClick={() =>
								this.setState({
									materialList: !this.state.materialList,
									sortList: false,
									typeList: false,
									metalList: false,

									weigthList: false
								})
							}>
							<div className="mob-filter-by-material mob-filter-type">
								<p>Material: {this.props.material}</p>
								<div className="dd-icon">
									<FaChevronCircleDown className="dropdown" />
								</div>
							</div>
							{this.state.materialList && (
								<div className="list">
									<p onClick={() => this.productMaterialHandler("Any")} className="sub-menu-item">
										Any
										{"Any" === this.props.material ? (
											<span>
												<i class="fas fa-check"></i>
											</span>
										) : null}
									</p>
									{this.state.categories.map((item, index) => {
										return (
											<p onClick={() => this.productMaterialHandler(item)} className="sub-menu-item" key={index}>
												{item}
												{item === this.props.material ? (
													<span>
														<i class="fas fa-check"></i>
													</span>
												) : null}
											</p>
										);
									})}
								</div>
							)}
						</div>
						<div
							className="mob-filter-menu"
							onClick={() =>
								this.setState({
									metalList: !this.state.metalList,
									sortList: false,
									typeList: false,

									materialList: false,
									weigthList: false
								})
							}>
							<div className="mob-filter-by-prodyct-type mob-filter-type">
								<p>Metal : {this.props.metal}</p>
								<div className="dd-icon">
									<FaChevronCircleDown className="dropdown" />
								</div>
							</div>
							{this.state.metalList && (
								<div className="list">
									<p onClick={() => this.productMetalHandler("Any")} className="sub-menu-item">
										Any
										{"Any" === this.props.metal ? (
											<span>
												<i class="fas fa-check"></i>
											</span>
										) : null}
									</p>
									{this.state.tags.map((item, index) => {
										return (
											<p onClick={() => this.productMetalHandler(item)} className="sub-menu-item" key={index}>
												{item}
												{item === this.props.metal ? (
													<span>
														<i class="fas fa-check"></i>
													</span>
												) : null}
											</p>
										);
									})}
								</div>
							)}
						</div>
						<div className="mob-filter-menu" onClick={() => this.setState({ sortList: !this.state.sortList, typeList: false, metalList: false, materialList: false, weigthList: false })}>
							<div className="mob-filter-by-see-more mob-filter-type">
								<p>Sort By: {this.props.sort}</p>
								<div className="dd-icon">
									<FaChevronCircleDown className="dropdown" />
								</div>
							</div>
							{this.state.sortList && (
								<div className="list">
									{this.state.sortItem.map((item, index) => {
										return (
											<p onClick={() => this.sortByHandler(item)} className="sub-menu-item" key={index}>
												{item}
												{item === this.props.sort ? (
													<span>
														<i class="fas fa-check"></i>
													</span>
												) : null}
											</p>
										);
									})}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MobileFilter;
