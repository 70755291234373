import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";




// Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig = {
    apiKey: "AIzaSyBDXIBHbUxxe_qP8I7O50Ny9orRPnYhM4E",
    authDomain: "mukund-jewellers.firebaseapp.com",
    databaseURL: "https://mukund-jewellers-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "mukund-jewellers",
    storageBucket: "mukund-jewellers.appspot.com",
    messagingSenderId: "737648106663",
    appId: "1:737648106663:web:f5b702935c3d89146f8f82",
    measurementId: "G-RT89C4FZD1"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
//   firebase.analytics();

export default firebase;