import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./App.css";
import ContactPage from "./layout/pages/Contact/contact.page";
import HomePage from "./layout/pages/Homepage/home.page";
import ProductDescription from "./layout/pages/ProductDescription/product.page";
// import AllProducts from "./layout/pages/AllProducts/AllProducts";
import AllProducts from "./layout/pages/AllProducts/allProducts";
import Navbar from "./layout/components/Navbar/navbar.component";
import Footer from "./layout/components/Footer/footer.component";
import MobileNav from "./layout/components/MobileNav/MobileNav";
import firebase from "./config/firebaseConfig";

const App = () => {
	React.useEffect(() => {
		console.log(firebase);
		window.addEventListener("mousemove", cursor);
	}, []);

	const cursor = (e) => {
		var mouseCursor1 = document.querySelector(".cursor");
		var mouseCursor2 = document.querySelector(".dot");
		var navLinks = document.querySelectorAll(".menu-item");
		var navLinks1 = document.querySelectorAll(".exclusive-grid");
		var navLinks2 = document.querySelectorAll(".gemstone-grid");
		var navLinks3 = document.querySelectorAll(".footer-link");
		var navLinks4 = document.querySelectorAll(".custom-link");
		var navLinks5 = document.querySelectorAll(".sub-menu-item");
		var navLinks6 = document.querySelectorAll(".card-main-container");
		var bannerDots = document.querySelectorAll(".slider-dot");
		mouseCursor1.style.top = e.pageY + "px";
		mouseCursor1.style.left = e.pageX + "px";
		mouseCursor2.style.top = e.pageY + "px";
		mouseCursor2.style.left = e.pageX + "px";
		navLinks.forEach((link) => {
			link.addEventListener("mouseleave", () => {
				mouseCursor1.classList.remove("link-grow");
				mouseCursor2.classList.remove("hide");
			});
			link.addEventListener("mouseover", () => {
				mouseCursor1.classList.add("link-grow");
				mouseCursor2.classList.add("hide");
			});
		});
		navLinks1.forEach((link1) => {
			link1.addEventListener("mouseleave", () => {
				mouseCursor1.classList.remove("link-grow");
				mouseCursor2.classList.remove("hide");
			});
			link1.addEventListener("mouseover", () => {
				mouseCursor1.classList.add("link-grow");
				mouseCursor2.classList.add("hide");
			});
		});
		navLinks2.forEach((link2) => {
			link2.addEventListener("mouseleave", () => {
				mouseCursor1.classList.remove("link-grow");
				mouseCursor2.classList.remove("hide");
			});
			link2.addEventListener("mouseover", () => {
				mouseCursor1.classList.add("link-grow");
				mouseCursor2.classList.add("hide");
			});
		});
		navLinks3.forEach((link3) => {
			link3.addEventListener("mouseleave", () => {
				mouseCursor1.classList.remove("link-grow");
				mouseCursor2.classList.remove("hide");
			});
			link3.addEventListener("mouseover", () => {
				mouseCursor1.classList.add("link-grow");
				mouseCursor2.classList.add("hide");
			});
		});
		navLinks4.forEach((link4) => {
			link4.addEventListener("mouseleave", () => {
				mouseCursor1.classList.remove("link-grow");
				mouseCursor2.classList.remove("hide");
			});
			link4.addEventListener("mouseover", () => {
				mouseCursor1.classList.add("link-grow");
				mouseCursor2.classList.add("hide");
			});
		});
		navLinks5.forEach((link5) => {
			link5.addEventListener("mouseleave", () => {
				mouseCursor1.classList.remove("link-grow");
				mouseCursor2.classList.remove("hide");
			});
			link5.addEventListener("mouseover", () => {
				mouseCursor1.classList.add("link-grow");
				mouseCursor2.classList.add("hide");
			});
		});
		navLinks6.forEach((link6) => {
			link6.addEventListener("mouseleave", () => {
				mouseCursor1.classList.remove("link-grow");
				mouseCursor2.classList.remove("hide");
			});
			link6.addEventListener("mouseover", () => {
				mouseCursor1.classList.add("link-grow");
				mouseCursor2.classList.add("hide");
			});
		});
		bannerDots.forEach((bannerDot) => {
			bannerDot.addEventListener("mouseleave", () => {
				mouseCursor1.classList.remove("link-grow");
				mouseCursor2.classList.remove("hide");
			});
			bannerDot.addEventListener("mouseover", () => {
				mouseCursor1.classList.add("link-grow");
				mouseCursor2.classList.add("hide");
			});
		});
	};

	return (
		<div className="App">
			<div className="cursor"></div>
			<div className="dot"></div>
			<Navbar />
			<MobileNav />
			<BrowserRouter>
				<Switch>
					<Route exact path="/" component={HomePage} />
					<Route exact path="/product/:id" component={ProductDescription} />
					<Route exact path="/contact" component={ContactPage} />
					<Route exact path="/jewellery/:query1/:query2/:query3/:query4" component={AllProducts} />
				</Switch>
			</BrowserRouter>
			<Footer />
		</div>
	);
};

export default App;
