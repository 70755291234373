import React, { Component } from "react";
import firebase from "firebase";
import { FaChevronCircleDown } from "react-icons/fa";

import "./Filter.css";

class Filter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeClass: false,
			box_1: false,
			box_2: false,
			box_3: false,
			box_4: false,
			categories: [],
			subCategories: [],
			tags: [],
			searchItem: "",
			sortItem: ["Latest", "Exclusive"],
			selectedSortitem: "Latest"
		};
	}

	async componentDidMount() {
		var settingsSnap = await firebase.firestore().collection("settings").doc("ZMuKUVDQPzQdcwkkFGqv").get();
		var categories = settingsSnap.data().categories;
		var types = [];
		var materials = [];
		var metals = [];
		for (var i = 0; i < categories.length; i++) {
			materials.push(categories[i].name);
			console.log(categories[i].subcategories.length);
			for (var j = 0; j < categories[i].subcategories.length; j++) {
				if (!types.includes(categories[i].subcategories[j].name)) {
					types.push(categories[i].subcategories[j].name);
					for (var k = 0; k < categories[i].subcategories[j].tags.length; k++) {
						if (!metals.includes(categories[i].subcategories[j].tags[k].tag)) {
							metals.push(categories[i].subcategories[j].tags[k].tag);
						}
					}
				}
			}
		}
		this.setState({
			categories: materials,
			subCategories: types,
			tags: metals
		});
	}

	productTypeHandler = (e) => {
		this.props.handleFilter("Type", e);
	};

	productMaterialHandler = (e) => {
		this.props.handleFilter("Material", e);
	};

	productMetalHandler = (e) => {
		this.props.handleFilter("Metal", e);
	};

	sortByHandler = (e) => {
		this.props.handleFilter("Sort", e);
	};

	render() {
		return (
			<div className="filter-container">
				<div className="filter-content">
					<div className="filter-body mob-filter-body">
						<div className="filter-menu">
							<div className="filter-by-prodyct-type filter-type">
								<p>Probuct Type : {this.props.type}</p>
								<div className="dd-icon">
									<FaChevronCircleDown className="dropdown" />
								</div>
							</div>
							<div className="list">
								<p onClick={() => this.productTypeHandler("Any")} className="sub-menu-item">
									Any
									{"Any" === this.props.type ? (
										<span>
											<i class="fas fa-check"></i>
										</span>
									) : null}
								</p>
								{this.state.subCategories.map((item, index) => {
									return (
										<p onClick={() => this.productTypeHandler(item)} className="sub-menu-item" key={index}>
											{item}
											{item === this.props.type ? (
												<span>
													<i class="fas fa-check"></i>
												</span>
											) : null}
										</p>
									);
								})}
							</div>
						</div>
						<div className="filter-menu">
							<div className="filter-by-material filter-type">
								<p>Material: {this.props.material}</p>
								<div className="dd-icon">
									<FaChevronCircleDown className="dropdown" />
								</div>
							</div>
							<div className="list">
								<p onClick={() => this.productMaterialHandler("Any")} className="sub-menu-item">
									Any
									{"Any" === this.props.material ? (
										<span>
											<i class="fas fa-check"></i>
										</span>
									) : null}
								</p>
								{this.state.categories.map((item, index) => {
									return (
										<p onClick={() => this.productMaterialHandler(item)} className="sub-menu-item" key={index}>
											{item}
											{item === this.props.material ? (
												<span>
													<i class="fas fa-check"></i>
												</span>
											) : null}
										</p>
									);
								})}
							</div>
						</div>
						<div className="filter-menu">
							<div className="filter-by-prodyct-type filter-type">
								<p>Metal : {this.props.metal}</p>
								<div className="dd-icon">
									<FaChevronCircleDown className="dropdown" />
								</div>
							</div>
							<div className="list">
								<p onClick={() => this.productMetalHandler("Any")} className="sub-menu-item">
									Any
									{"Any" === this.props.metal ? (
										<span>
											<i class="fas fa-check"></i>
										</span>
									) : null}
								</p>
								{this.state.tags.map((item, index) => {
									return (
										<p onClick={() => this.productMetalHandler(item)} className="sub-menu-item" key={index}>
											{item}
											{item === this.props.metal ? (
												<span>
													<i class="fas fa-check"></i>
												</span>
											) : null}
										</p>
									);
								})}
							</div>
						</div>
						<div className="filter-menu">
							<div className="filter-by-see-more filter-type">
								<p>Sort By: {this.props.sort}</p>
								<div className="dd-icon">
									<FaChevronCircleDown className="dropdown" />
								</div>
							</div>
							<div className="list">
								{this.state.sortItem.map((item, index) => {
									return (
										<p onClick={() => this.sortByHandler(item)} className="sub-menu-item" key={index}>
											{item}
											{item === this.props.sort ? (
												<span>
													<i class="fas fa-check"></i>
												</span>
											) : null}
										</p>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Filter;
