import React from "react";
import { Link } from "react-router-dom";
import "./Card.css";

const Card = (props) => {
	return (
		<Link to={`/product/${props.item.id}`} className="card-main-container">
			<div className="card-body">
				<div className="card-image-container">
					<img src={props.item.thumbnail} alt="Product-Thumbnail" />
				</div>
			</div>
			<div className="card-footer">
				<p>{props.item.title}</p>
			</div>
		</Link>
	);
};

export default Card;
